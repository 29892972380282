module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"University Department of Chemistry","short_name":"Univ. Dept. of Chem.","icon":"static/images/maskable_icon.png","start_url":"/","background_color":"#20232a","theme_color":"#20232a","display":"standalone","purpose":"any maskable","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f5a77cac91af229f68da675216bfe4ee"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
